<template>
  <div class="view-root view-content edit-note-container bg-white">
    <div class="flex justify-between items-center mb-6">
      <p class="title">Article Detail</p>
      <Avatar />
    </div>
    <div class="flex gap-6">
      <div class="edit-content-container">
        <div class="flex items-center justify-between">
          <div class="custom-btn back" @click="goBack">
            <img class="btn-icon" src="../assets/back.png" />
            Back
          </div>
        </div>
        <div class="edit-note">
          <div class="flex items-center justify-between note-info">
            <div>
              {{
                $dayjs(cardNoteInfo.create_time * 1000).format("MMMM D, YYYY")
              }}
            </div>
            <div class="flex items-center gap-2">
              <p class="publisher" v-if="cardNoteInfo.user_info && cardNoteInfo.user_info.user_nickName">Publisher: {{ cardNoteInfo.user_info.user_nickName }}</p>
              <img class="view-icon" src="../assets/view.png" />{{
                cardNoteInfo.read_number
              }}
            </div>
          </div>
          <div class="note-input">
            <p class="note-title" v-html="cardNoteInfo.title"></p>

            <div class="flex items-center justify-between mb-4">
              <div class="tags-container">
                <div v-for="(tag, index) in labels.filter((i) => !!i)" class="tag" :key="index" :style="{
                  color: TagColorList[index].color,
                  background: TagColorList[index].background,
                  cursor: isInEditMode ? 'pointer' : 'auto',
                }" @click="deleteLabel(index)">
                  <div class="tag-text">{{ tag }}</div>
                </div>
              </div>
              <div class="custom-btn ml-6" style="height: 34px; border-radius: 8px; padding: 0 12px" v-if="isInEditMode"
                @click="showTagModal = true">
                <img src="../assets/add.png" class="btn-icon" />
              </div>
            </div>

            <p class="note-content" v-html="cardNoteInfo.content"></p>
          </div>
        </div>
      </div>
      <div class="timeline-container" v-if="remindTimes && remindTimes.length > 0">
        <p class="timeline-title">Wake-up mails</p>
        <div class="timeline-item-wrapper mt-6">
          <div class="flex items-center justify-between timeline-item" v-for="(item, index) in remindTimes" :key="index"
            :class="item.class">
            <div class="detail flex items-center">
              <!-- <div class="dot"></div> -->
              <el-checkbox :checked="item.push_status == 'yes'" :disabled="true" label="" style="margin-right: 12px"></el-checkbox>
              <p>{{ item.remind_label }}</p>
            </div>
            <p>{{ $dayjs(item.remind_time * 1000).format("MMM D, YYYY") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArchiveArticle } from "../api/reff";
import { decryptValue, encryptValue } from "../utils/common";
import Avatar from "../components/Avatar.vue";
import { Loading } from "element-ui";
import "../assets/common/common.css";
import "../assets/common/font.css";
import { getUserId } from "../utils/store";

export default {
  name: "index",
  components: {
    Avatar,
  },
  data() {
    return {
      isInEditMode: false,
      id: "",
      noteInfo: {},
      showTime: "",
      decryptTitle: "",
      decryptContent: "",
      labels: [],
      showAddTagDialog: false,
      tempTag: "",
      loadingInstance: undefined,
      remindTimes: [],
      cardNoteInfo: {},
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.fetchData();
  },
  methods: {
    showDeleteNoteTips() {
      this.$alert("Do you want to delete this note?", "Note", {
        confirmButtonText: "DELETE",
        callback: (action) => {
          if (action == "confirm") {
            removeNote(this.id).then((res) => {
              this.$message.success("Deleted");
              this.$router.go(-1);
            });
          }
        },
      });
    },

    formatBtnStyle() {
      let style = {};
      if (this.isInEditMode) {
        style.backgroundColor = "#FFC569";
        style.color = "white";
        style.borderColor = "#FFC569";
      } else {
        style.backgroundColor = "white";
        style.color = "#333333";
        style.borderColor = "#DCDFE6";
      }
      return style;
    },

    addTag() {
      if (this.tempTag == "") {
        this.$message.error("Please input");
        return;
      }
      this.labels.push(this.tempTag);
      this.tempTag = "";
      this.showAddTagDialog = false;
    },

    deleteLabel(index) {
      if (this.isInEditMode == false) {
        return;
      }
      this.$alert("Are you sure remove this tag?", "Tips", {
        confirmButtonText: "Sure",
        callback: (action) => {
          if (action == "confirm") {
            this.labels.splice(index, 1);
          }
        },
      });
    },

    fetchData() {
      const loadingInstance = Loading.service({
        fullscreen: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      getArchiveArticle({ id: this.id })
        .then((res) => {
          let result = res.data.data;
          this.cardNoteInfo = result.article_info;
          this.formatCreateTime();

          if (result.reminds) {
            const reminds = result.reminds.sort(
              this.compare("remind_time", true)
            );
            this.remindTimes = reminds.map((obj) => {
              if (obj.push_status === "yes") {
                return {
                  ...obj,
                  class: "done",
                  remind_label: this.formatRemindPushedLabel(obj.remind_type),
                };
              } else if (reminds.find((o) => o.push_status === "no") === obj) {
                return {
                  ...obj,
                  class: "during",
                  remind_label: this.formatRemindPushedLabel(obj.remind_type),
                };
              } else {
                return {
                  ...obj,
                  class: "noStart",
                  remind_label: this.formatRemindPushedLabel(obj.remind_type),
                };
              }
            });
          }
        })
        .finally(() => loadingInstance.close());
    },
    compare(property, desc) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        if (desc === true) {
          // 升序排列
          return value1 - value2;
        } else {
          // 降序排列
          return value2 - value1;
        }
      };
    },
    formatRemindPushedLabel(remind_type) {
      switch (remind_type) {
        case "three_days":
          return "3 days";
        case "week":
          return "1 Week";
        case "month":
          return "1 Month";
        case "six_month":
          return "6 Months";
        case "one_year":
          return "1 year";
        case "two_year":
          return "2 years";
        case "four_year":
          return "4 years";
      }
    },
    formatCreateTime() {
      let create_time = this.cardNoteInfo.create_time;
      var date = new Date(create_time * 1000);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      this.showTime = this.formatMonthToEng(month) + " " + day + ", " + year;
    },
    doDecryptValue(encrypted) {
      return decryptValue(encrypted);
    },
    encryptLabels(labels) {
      let newArray = [];
      for (let i = 0; i < labels.length; i++) {
        let encryptLabel = encryptValue(labels[i]);
        newArray.push(encryptLabel);
      }
      return newArray;
    },
    goBack() {
      this.$router.go(-1);
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "Jan";
        case 2:
          return "Feb";
        case 3:
          return "Mar";
        case 4:
          return "Apr";
        case 5:
          return "May";
        case 6:
          return "Jun";
        case 7:
          return "Jul";
        case 8:
          return "Aug";
        case 9:
          return "Sep";
        case 10:
          return "Oct";
        case 11:
          return "Nov";
        case 12:
          return "Dec";
      }
    },
  },
};
</script>

<style scoped lang="scss">


.publisher {
  font-family: "Poppins";
    background-color: #ffe9e1;
    color: #ec652b;
    border-radius: 8px;
    padding: 2px 10px;
    box-sizing: border-box;
  }

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec652b;
  border-color: #ec652b;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #ec652b;
}

.edit-note-container {
  padding: 32px;
  overflow-y: scroll;
  position: relative;

  .title {
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #1a202c;
  }

  .timeline-container {
    width: 360px;
    height: fit-content;
    font-family: "Poppins";
    border: 1px solid #edf2f7;
    border-radius: 12px;
    padding: 24px;

    .timeline-title {
      font-size: 22px;
      font-weight: 600;
      color: #1a202c;
    }

    .timeline-item-wrapper {
      .timeline-item {
        font-size: 15px;
        margin-bottom: 12px;
        &.done {
          color: #a0aec0;

          .detail {
            .dot {
              background: #a0aec0;
            }
          }
        }

        &.during {
          color: #ec652b;

          .detail {
            .dot {
              background: #ec652b;
            }
          }
        }

        

        .detail {
          font-weight: 600;

          .dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin-right: 12px;
            background: #1a202c;
          }



        }
      }
    }
  }
  .edit-content-container {
    flex: 1;

    .edit-note {
      border-radius: 16px;
      border: 1px solid #edf2f7;
      padding: 24px;
      margin-top: 24px;
      min-height: calc(100vh - 202px);
    }

    .note-info {
      color: #005a64;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      .view-icon {
        width: 14px;
        height: 14px;
      }
    }

    .note-input {
      .note-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        font-family: "Poppins";
        color: #1a202c;
        margin: 16px 0;
        width: 100%;
      }

      .note-content {
        width: 100%;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;
        font-family: Manrope;
        color: #718096;
      }

      &::v-deep .el-input__inner {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        background: transparent;
        font-family: "Poppins";
        color: #1a202c;
        margin: 16px 0;
        font-weight: 600 !important;
      }

      &::v-deep .el-textarea__inner {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;
        background: transparent;
        font-family: Manrope;
        color: #718096;
        cursor: auto !important;
      }
    }
  }

  .custom-btn {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    color: white;
    font-family: Poppins;
    background: #ec652b;
    height: 48px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;

    .btn-icon {
      width: 16px;
      height: 16px;
    }

    &.delete-btn {
      background: #fe5024;
    }

    &.back {
      color: #ec652b;
      border: 2px solid #ec652b;
      height: 48px;
      background: white;
    }
  }
}

.delete-content {
  font-size: 14px;
  color: #a0aec0;
  margin: 24px 0;
  width: 100%;
  text-align: center;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .tag {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    border-radius: 8px;
    font-size: 12px;
    padding: 8px 12px;

    .tag-text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
